<template>
    <div class="history-page">
        <layout :header="$t('purchaseHistoryMenu')" @goback="$router.push('/')">
            <template v-slot:content>
                <v-row v-if="!loadingState && history.length > 0">
                    <v-col class="card-history" cols="12" v-for="(item,index) in history" :key="index+'item'">
                        <v-row class="history-item px-2 py-1" dense @click="$router.push('/Receipt/'+ item.objectId)">
                            <v-col cols="8" align-self="center">
                                <div class="title-his">
                                    {{$i18n.locale == 'th' ? item.coupon_id.name || '': item.coupon_id.name_en || ''}}
                                </div>
                                <div class="date-his">{{DateTime(item.purchased_at.iso)}}</div>
                            </v-col>
                            <v-col cols="4" class="text-right" align-self="center">
                                <div class="price-his">
                                    {{formatMoney(item.price, item.type != 'jfin' ? 2 : 7)}}
                                    <span v-if="item.type != 'jfin'">{{$i18n.locale == 'th' ? 'บาท': 'THB'}}</span>
                                    <span v-else>JFIN</span>
                                </div>
                                <div class="skul-his">{{item.status}}</div>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row justify="center" align="center" v-else-if="!loadingState && history.length == 0">
                    <v-col cols="12" xs="12" sm="8" md="6">
                        <v-card elevation="1" class="rounded-lg pa-5 text-center">
                            {{$t('nodata')}}
                        </v-card>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col class="card-history" cols="12" v-for="item in 5" :key="item+'item'">
                        <skeleton type="list-item-two-line"></skeleton>
                    </v-col>
                </v-row>
            </template>
        </layout>
        <loading v-show="loadingState"></loading>
    </div>
</template>

<script>
    import Layout from "../../components/layout_1";
    import Loading from "../../components/loading";
    import skeleton from "../../components/skeleton";
    export default {
        name: "History",
        components: {
            layout: Layout,
            loading: Loading,
            skeleton: skeleton,
        },
        data: () => ({
            loadingState: true,
        }),
        computed: {
            history() {
                return this.$store.state.moduleStore.history;
            },
        },
        async beforeCreate() {
            await this.$store.dispatch("get_coupon_purchase_history");
            await this.$store.dispatch("getBalance");
            this.loadingState = false;
        },
    };
</script>

<style lang="scss">
    @import "@/assets/scss/color.scss";

    .history-page {
        height: 100%;

        .card-history:not(:last-child) {
            padding-bottom: 10px;
        }

        .card-history {
            padding-left: 16px;
            padding-right: 16px;
            display: flex;
            justify-content: center;

            .history-item {
                border: 1px solid $accent-color;
                border-radius: 8px;
                background-color: white;
                max-width: 600px;

                .title-his {
                    font-size: 14px;
                    font-weight: 800;
                }

                .date-his {
                    color: #969696;
                    font-size: 11px;
                }

                .price-his {
                    font-size: 15px;
                    color: $primary-color;
                    font-weight: 800;
                }

                .skul-his {
                    font-weight: 800;
                    color: #969696;
                    font-size: 12px;
                }
            }
        }
    }
</style>